import Baners from "./baners/Baners";
import RecentArticles from "./recentArticles/RecentArticles";
import Sliders from "./sliders/Sliders";
import "./HomePage.scss";
import CategoriesPage from "../articlesCategories/categories/CategoriesPage";
import { useSelector } from "react-redux";

const HomePage = () => {
  const menu = useSelector((store: any) => store.articleCategory);
  return (
    <div className="Page">
      <Sliders />
      <div className="Page-Content">
        <div className="Home-Page">
          <Baners />
          {menu.is_fetch && (
            <CategoriesPage
              cat1={undefined}
              cat2={undefined}
              cat3={undefined}
              menu={menu}
              getCurrentCategoryName={() => "Kategorije"}
            />
          )}
          <RecentArticles slug={null}/>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
