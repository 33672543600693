import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { RecentArticles } from "../homePage";
import BreadcrumbArticles from "./breadcrumb/BreadcrumbArticles";
import CategoriesPage from "./categories/CategoriesPage";
import ArticlesPage from "./articles/ArticlesPage";;

const ArticlesCategoriesPage = () => {
  let { cat1, cat2, cat3 } = useParams();
  const menu = useSelector((store: any) => store.articleCategory);

  const [showCategories, setShowCategories] = useState<boolean | null>(null);

  useEffect(() => {
    if (cat3 !== undefined) {
      setShowCategories(false);
    } else if (cat2 !== undefined) {
      setShowCategories(true);
    } else if (cat1 !== undefined) {
      setShowCategories(true);
    } else {
      setShowCategories(true);
    }
  }, [cat1, cat2, cat3]);

  const getBreadcrumbsLabel = () => {
    let items = [];
    let label1 = "";
    let label2 = "";
    let label3 = "";
    if (cat1) {
      items = menu.article_category.filter((x: any) => x.slug === cat1);
      label1 = items[0] ? items[0].ac_name : "";
      
    }
    if (cat2) {
      items = items[0]
        ? items[0].subcategories.filter((y: any) => y.slug === cat2)
        : items;
      label2 = items[0] ? items[0].ac_name : "";
    }
    if (cat3) {
      items = items[0]
        ? items[0].subcategories.filter((z: any) => z.slug === cat3)
        : items;
      label3 = items[0] ? items[0].ac_name : "";
    }
    return {label1, label2, label3}
  }
  const getCurrentCategoryName = () => {
    const {label1, label2, label3} = getBreadcrumbsLabel();
    if(cat3){
      return label3;
    }else if(cat2){
      return label2;
    }else if(cat1){
      return label1;
    }else{
      return "Kategorije"
    }
  }
  const getCurrentCategorySlug = () => {
    if(cat3){
      return cat3;
    }else if(cat2){
      return cat2;
    }else if(cat1){
      return cat1;
    }else{
      return undefined
    }
  }
 

  return (
    <div className="Page">
      <div className="Page-Content">
        <BreadcrumbArticles cat1={cat1} cat2={cat2} cat3={cat3} menu={menu} getBreadcrumbsLabel={getBreadcrumbsLabel} />
        {showCategories === true && (
          <>
            <CategoriesPage cat1={cat1} cat2={cat2} cat3={cat3} menu={menu} getCurrentCategoryName={getCurrentCategoryName} />
            <RecentArticles slug={getCurrentCategorySlug()}/>
          </>
        )}
        {showCategories === false && 
          <ArticlesPage cat1={cat1} cat2={cat2} cat3={cat3} menu={menu} />
        }
      </div>
    </div>
  );
};
export default ArticlesCategoriesPage;
