import { useDispatch, useSelector } from "react-redux";
import "./RecentArticles.scss";
import { useEffect } from "react";

import { ArticleType } from "../../../../types/ArticleType";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffectDeviceAndOrientation } from "../../../../utils/hooks";
import { fetchRecentArticles } from "../../../../store/articles/articles.slice";
import ArticleCard from "../../article/ArticleCard";
import { useParams } from "react-router-dom";

type RecentArticlesProps = {
  slug: string | null | undefined; 
  // recent_articles: any;
};

const RecentArticles : React.FC<RecentArticlesProps>  = ({slug}) => {
  let { cat1, cat2, cat3 } = useParams();
  const { recent_articles } = useSelector((store: any) => store.articles);
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(fetchRecentArticles(slug));
  }, [dispatch, cat1, cat2, cat3]);

  if (!recent_articles.is_fetch) {
    return <LoadingOutlined />;
  } else {
    return (
      <div className="Recent-Articles">
        <h2 className="Page-Title">Nedavno dodati proizvodi</h2>
        <div className="Recent-Aricles-Container">
          {recent_articles.list.map((article: ArticleType, id: number) => {
            return (
              <div className="Recent-Aricle-Item" key={article.article_slug}>
                <ArticleCard article={article} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default RecentArticles;
