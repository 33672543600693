import { Link, useNavigate } from "react-router-dom";
import { Avatar, Rate, Tooltip } from "antd";
import {
  HeartFilled,
  HeartOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

import { PhotoDisplayHandler } from "../../../components";
import "./ArticleCard.scss";
import { truncateString } from "../../../utils/helpers/strings";
import LinkLanguage from "../../../locales/LinkLanguage";
import { addArticle } from "../../../store/cart/cart.slice";
import { useDispatch } from "react-redux";
import { showToast } from "../../../utils/helpers/toastHelper";
import { IoCallOutline } from "react-icons/io5";

const ArticleCard = ({ article }: any) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const addToCart = () => {
    dispatch(
      addArticle({
        price: article.article_price.price,
        discount: article.article_price.discount,
        old_price: article.article_price.old_price,
        slug: article.article_slug,
        name: article.article_name,
        image: article.background_image,
        quantity: 1,
      })
    );
    showToast(`Proizvod dodat u korpu`, "success");
  };

  const addToLiked = () => {
    // alert("lajkovano");
  };

  return (
    <div className="Aticle-Box">
      <div className="Aticle-Box-Div Article-Box-Line1">
        {article.article_price.is_visible && article.article_price.on_sale && (
          <div className="Action">
            Sniženje
          </div>
        )}
        {/* TODO lajkovani proizvodi */}
        {/* <div className="Icon" onClick={addToLiked}>
          <HeartOutlined />
        </div> */}
      </div>

      <LinkLanguage
        to={`/article/${article.article_slug}`}
        title={article.article_slug}
        className="Aticle-Box-Div Article-Box-Line2"
      >
        <PhotoDisplayHandler
          img_name={article.background_image}
          calculate={false}
          img_class_name="Article-Box-Image"
          img_alt={article.article_name}
          width="auto"
          height="100%"
        />
      </LinkLanguage>

      <div className="Aticle-Box-Div Article-Box-Line3">
        {/* TODO RATE JOS NIJE DODAT  */}
        {/* <Rate
          className="Rate"
          disabled
          allowHalf
          defaultValue={article.avg_rate}
          value={4}
        /> */}
      </div>
      <LinkLanguage
        to={`/article/${article.article_slug}`}
        title={article.article_slug}
        className="Aticle-Box-Div Article-Box-Line4"
      >
        <h2 className="Article-Card-Title">
          {truncateString(article.article_name, 100)}
          {/* max 100 karaktera */}
        </h2>
      </LinkLanguage>
      {article.article_price.is_visible ? (
        <div className="Aticle-Box-Div Article-Box-Line5 Row">
          <LinkLanguage
            to={`/article/${article.article_slug}`}
            title={article.article_slug}
            className="Left"
          >
            <div
              className="Label1"
              style={{
                visibility: article.article_price.on_sale
                  ? "visible"
                  : "hidden",
              }}
            >
              {article.article_price.old_price} <span className="Label1">KM</span>
            </div>
            <div className="Label2">
              {article.article_price.price} <span className="Label2">KM</span>
            </div>

            <div
              className="Label3"
              style={{
                visibility: article.article_price.on_sale
                  ? "visible"
                  : "hidden",
              }}
            >
              Ušteda{" "}
              {(
                article.article_price.old_price - article.article_price.price
              ).toFixed(2)}{" "}
              KM
            </div>
          </LinkLanguage>

          <div className="Right">
            <Tooltip title="Dodaj u korpu">
              <button onClick={addToCart} className="Round-Button">
                <Avatar
                  icon={<ShoppingCartOutlined />}
                  size={"large"}
                  className="Button-Avatar-Gray"
                />
              </button>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="Aticle-Box-Div Article-Box-Line5">
          <label className="Label-Block Label4">Cijena na upit</label>
          <Tooltip title="Kontaktiraj nas">
            <button
              onClick={() => navigate("/contact")}
              className="Round-Button"
            >
              <Avatar
                icon={<IoCallOutline />}
                size={"large"}
                className="Button-Avatar-Gray"
              />
            </button>
          </Tooltip>
         
        </div>
      )}
    </div>
  );
};
export default ArticleCard;
